import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Portal from './pages/Portal'
import SelectDate from './pages/SelectDate'
import SelectCancha from './pages/SelectCancha'
import Confirm from './pages/Confirm'
import CreateDate from './pages/CreateDate'
import ClassInfo from './pages/ClassInfo';
import Info from './pages/Info';
import PrivacityPolice from './pages/PrivacityPolice';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Portal/>} />
        <Route exact path="/info" element={<Info/>} />
        <Route exact path="/ClassInfo" element={<ClassInfo/>}/>
        <Route exact path="/createDate" element={<CreateDate/>} />
        <Route exact path="/SelectDate/:type" element={<SelectDate/>} />
        <Route exact path="/SelectCancha/:type/:saleDate" element={<SelectCancha/>} />
        <Route exact path="/Privacity" element={<PrivacityPolice/>} />
        <Route exact path="/SelectHour/:type/:saleDate/:cancha" element={<Home/>} />
        <Route exact path="/Confirm/:type/:saleDate/:cancha/:hour/:hourId" element={<Confirm/>} />
      </Routes>
    </Router>
  );
};

export default App;