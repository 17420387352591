import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
//import {DatePicker} from '@gsebdev/react-simple-datepicker';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import PropTypes from 'prop-types';
require('moment/locale/es.js');

const localizer = momentLocalizer(moment) 



const SelectDate = () => {
  const navigate = useNavigate();
  const [saleDate, setSaleDate] = useState(new Date());
  const { type } = useParams();
  
   
  var day = new Array(6);
  day [0] = "Domingo";
  day [1] = "Lunes";
  day [2] = "Martes";
  day [3] = "Miércoles";
  day [4] = "Jueves";
  day [5] = "Viernes";
  day [6] = "Sábado";

   var month = new Array(11);
   month[0] = "Enero";
   month[1] = "Febrero";
   month[2] = "Marzo";
   month[3] = "Abril";
   month[4] = "Mayo";
   month[5] = "Junio";
   month[6] = "Julio";
   month[7] = "Agosto";
   month[8] = "Septiembre";
   month[9] = "Octubre";
   month[10] = "Noviembre";
   month[11] = "Diciembre";
  
  const onChangeCallback = ({target}) => {
    // a callback function when user select a date
    var d=moment(target.value, 'YYYY-MM-DD').toDate();
    //d.setDay(d.getDay()+1);
    setSaleDate(d);
    document.getElementById('lbl1').style="display:none";
         
  }


  const next=()=>{
    if(saleDate==null){
      document.getElementById('lbl1').style="display:block";
    }else{
      
      navigate("/SelectCancha/"+type+'/'+formatDate(saleDate));
    }
  }

  const formatDate= function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }


  return (
    <div className="portal-container">
     <header>
      <div className='row'>
           <div className='col-1'>
          <svg onClick={()=>navigate(-1)} xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="footerFont topBar">
  SELECCIONE FECHA</span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
           <div className='row'>
          <input className='formControl' onChange={onChangeCallback} min={formatDate(new Date())} value={formatDate(new Date())} type="date" lang="es-UY"></input>
          <span id="dateSel">
             <div className="footerFont" style={{padding: '5px', background:'#000',color:'#fff', textAlign:'center'}}>{day[saleDate.getDay()]} {saleDate.getDate()} {month[saleDate.getMonth()]}</div>
          </span>
    
    </div>

           <div className='row' style={{display:'none'}} id="lbl1">
            *Indique fecha antes de continuar
            </div>
         </div>
      </main>

      <footer className='footerBlanco'>
           <div className='row'>
            <div className='col-6' >
              <span onClick={()=>navigate(-1)} className="btn btn-sm btn-outline-secondary btnFont  text-secondary marginBtnToolBar" style={{paddingRight:'40px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-left-short arrow" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                INICIO</span>
              </div>
              <div className='col-6'>
                <span onClick={()=>next()} className="footerVerde btn btn-sm btn-outline-secondary btnFont text-secondary marginBtnToolBar">
                  CONTINUAR
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-right-short arrow" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  </span>
                </div>
          </div>
      </footer>
    </div>);
  };


export default SelectDate;

