import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import {PATH_TO_API,API_GET_HEADERS} from './util/constants';

  
const SelectCancha = () => {
  const navigate = useNavigate();
  const { type,saleDate } = useParams();
  const [canchas, setCanchas]=useState([]); 
  useEffect(()=>{
    async function fetchData(){
      fetch(PATH_TO_API('canchas'),API_GET_HEADERS).then(res=>res.json()).then(res=>{
        setCanchas(res.filter((c)=>((type=='clase' && c.id==1 )|| type!='clase')));
      })    
    }
    fetchData();
  },[]);

  const next=(cancha)=>{
     navigate("/SelectHour/"+type+'/'+saleDate+'/'+cancha);
    
  }
 

  return (
    <div className="portal-container">
     <header>
      <div className='row'>
           <div className='col-1'  onClick={()=>navigate(-1)} >
          <svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="footerFont topBar">
  SELECCIONE CANCHA</span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
           <div className='row'>
           {saleDate && 
            <div className='row footerFont' >
              Fecha seleccionada {saleDate}
            </div>}
            {canchas&& canchas.length>0 && canchas.map(
              (c,i)=>
              <div class="card" >
              <img className='cancha' src={c.cancha_img}></img>
              <div className="card-body">
                <h5 className="card-title">{c.cancha_title}</h5>
                <p className="card-text">{c.cancha_description}</p>
                <a href="" onClick={()=>next(c.id)} className="btn btn-sm btn-outline-secondary btnFont text-secondary">SELECCIONE</a>
              </div>
            </div>
              
            )
            }
           </div>
         </div>
      </main>

     
    </div>);
  };


export default SelectCancha;
