var API_PATH_BASE='https://www.openpadelsalto.com/api/';
module.exports.API_PATH_BASE=API_PATH_BASE;
module.exports.PATH_TO_API=function (path){ return API_PATH_BASE+path;};
module.exports.API_GET_HEADERS={
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
};
module.exports.nf=(n)=>{
    if(n<=9) return "0"+n;
    else return n;
}
