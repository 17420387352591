import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import {PATH_TO_API,API_GET_HEADERS,nf} from './util/constants';
import { CSVLink, CSVDownload } from "react-csv";


const CreateDate = () => {
  const navigate = useNavigate();
  const [saleDate, setSaleDate] = useState(new Date());
  const [saleEndDate, setSaleEndDate] = useState(new Date());
  const [canchas, setCanchas] = useState([]);
  const [hours, setHours] = useState([]);
  const [excel,setExcel]=useState([]);
  const [isLg, setIsLg]=useState(false);
  const [user,setUser] =useState(null);
  const [pwd,setPwd]=useState(null);

  var day = new Array(6);
       day [0] = "Domingo";
       day [1] = "Lunes";
       day [2] = "Martes";
       day [3] = "Miércoles";
       day [4] = "Jueves";
       day [5] = "Viernes";
       day [6] = "Sábado";

   var month = new Array(11);
        month[0] = "Enero";
        month[1] = "Febrero";
        month[2] = "Marzo";
        month[3] = "Abril";
        month[4] = "Mayo";
        month[5] = "Junio";
        month[6] = "Julio";
        month[7] = "Agosto";
        month[8] = "Septiembre";
        month[9] = "Octubre";
        month[10] = "Noviembre";
        month[11] = "Diciembre";

  const onChangeCallback = ({target}) => {
    // a callback function when user select a date
    var d=moment(target.value, 'YYYY-MM-DD').toDate();
    //d.setDay(d.getDay()+1);
    setSaleDate(d);
    document.getElementById('lbl1').style="display:none";

    document.getElementById('dateSel').innerHTML='<div style="padding: 5px; background: #000;color:#fff; text-align:center; font-weight: bold">' + day[d.getDay()] + ',&nbsp;' + d.getDate() + '&nbsp;de&nbsp;' + month[d.getMonth()]+'</div>';
        
  }

  
  const onChangeCallback2 = ({target}) => {
    var d=moment(target.value, 'YYYY-MM-DD').toDate();
    setSaleEndDate(d);
    
    document.getElementById('dateSel2').innerHTML='<div style="padding: 5px; background: #000;color:#fff; text-align:center; font-weight: bold">' + day[d.getDay()] + ',&nbsp;' + d.getDate() + '&nbsp;de&nbsp;' + month[d.getMonth()]+'</div>';
  }

  useEffect(()=>{
    async function fetchData(){
      fetch(PATH_TO_API('canchas/'),API_GET_HEADERS).then(res=>res.json()).then(res=>{
        setCanchas(res);
      })    
    }
    
    fetchData();
  },[]);

  async function fetchData(){
    await fetch(PATH_TO_API('all/hours/'+formatDate(saleDate)+'/'+formatDate(saleEndDate)),API_GET_HEADERS).then(res=>res.json()).then(res=>{
      setHours(res);
      var data=[res.length];
      for(var i=0;i<res.length;i++){
        data[i]={Fecha:formatDate(res[i].hour_date),Cancha:res[i].canchaId,Turno:res[i].hour_hour+':'+res[i].hour_time,Nombre:res[i].reservation_name,Telefono:res[i].reservation_phone,Pago_Jugador_1:res[i].player1_pay,Pago_Jugador_2:res[i].player2_pay,Pago_Jugador_3:res[i].player3_pay,Pago_Jugador_4:res[i].player4_pay};
      }
      setExcel(data);
    })
       
  }

  async function fixedData(h){
    fetch(PATH_TO_API('fixed/hour/'+h.id+'/'+h.quantity),API_GET_HEADERS).then(res=>res.json()).then(res=>{
      setHours([]);
    })
       
  }

  async function deleteData(h){
    await fetch(PATH_TO_API('delete/hour/'+h.id),API_GET_HEADERS).then(res=>res.json()).then(res=>{
      setHours([]);
    });
       
  }
  
  const search=()=>{
    fetchData();
  }


  const next=()=>{
    if(saleDate==null){
      document.getElementById('lbl1').style="display:block";
    }else{
      
      navigate("/SelectCancha/"+formatDate(saleDate));
    }
  }

  const getCancha=(id)=>{
    return canchas.filter((c)=>c.id==id)[0];
  }

  const fixed= async (h)=>{
    if(h.quantity==null || h.quantity<0){
      alert('Indique un numero mayor a 1, cantidad de veces para fijar la reserva o 0 para eliminar todas desde '+h.hour_date);
    }else{
      await fixedData(h);
      var d=moment(h.hour_date, 'YYYY-MM-DD').toDate();
      if(h.quantity>0){
        alert('Fijado todos los '+day[d.getDay()]+' por '+h.quantity+' semanas');
      }else{
        alert('Se eliminaron las reservas');
      }
      search();
    }
  }

  const deleteHour=async (h)=>{
    await deleteData(h);
    alert('Se elimino reserva '+h.reservation_name+'-'+h.reservation_phone+'-'+h.hour_date+'-'+h.hour_hour+':'+h.hour_time);
    search();
  }

  const formatDate= function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  const asyncUpdate=async (reserId,player,pay)=>{
    await fetch(PATH_TO_API('reser/update/'+reserId+'/'+player+'/'+pay),API_GET_HEADERS).then(res=>res.json()).then(res=>{
    });
  } 

  const update=async (h,reserId,player,pay)=>{
    await asyncUpdate(reserId,player,pay);
    await fetchData();
  } 

  const login= async()=>{
    await fetch(PATH_TO_API('login/'+user+'/'+pwd),API_GET_HEADERS).then(res=>res.json()).then(res=>{
      setIsLg(res.res);
    })
       
  }

  if(!isLg){
    return (
      <div className='portal-container'>
         <div className='row d-flex flex-row' style={{marginTop:'150px'}}>
          <div className='col p' style={{marginLeft:'27%'}}>
            <div className="input-group">
                <div class="input-group-prepend">
                  <span className="input-group-text" style={{width:'96px'}}>Usuario</span>
                </div>
                <input className='formControl' onChange={(e)=>setUser(e.target.value)} type="text" lang="es-UY"></input>
            </div> 
            <div className="input-group">
              <div class="input-group-prepend">
                <span className="input-group-text" >Password</span>
              </div>
             <input className='formControl' onChange={(e)=>setPwd(e.target.value)}  type="password" lang="es-UY"></input>
          </div>
          <input type="button" onClick={login} value="Entrar" className='formControl' style={{width:'310px'}}></input> 
          </div>
          <img className='logo' src="/img/logo.png"></img>
                 
        </div>
      </div>
    );
  }

  return (
    <div className="">
     <header>
      <div className='row'>
            <div className='col-11'>
          <span className="footerFont topBar">
  ADMINISTRAR HORARIOS</span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
           <div className='row'>
            <div className='col-12 '>
           <div className="input-group">
              <div class="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Desde</span>
              </div>
              <input className='formControl' onChange={onChangeCallback} value={formatDate(saleDate)} type="date" lang="es-UY"></input>
              <span id="dateSel"></span>
              <div class="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Hasta</span>
              </div>
             <input className='formControl' onChange={onChangeCallback2} value={formatDate(saleEndDate)} type="date" lang="es-UY"></input>
             <span id="dateSel2"></span>
            </div>
          </div>
          </div>
           <div  className='row' style={{display:'none'}} id="lbl1">
            *Indique fecha antes de continuar
            </div>
            <input type="button" onClick={search} value="Buscar" className='formControl' style={{width:'100%'}}></input>
         </div>
         {hours && hours.length>0 &&
         <div className='row'>
           <CSVLink data={excel} target="_blank">Excel</CSVLink>
         </div>
          }
         <table class="table">
  <thead>
    <tr>
      <th scope="col">Fecha</th>
      <th scope="col">Cancha</th>
      <th scope="col">Turno</th>
      <th scope="col">Nombre</th>
      <th scope="col">Telefono</th>
      <th scope="col">Jugador 1</th>
      <th scope="col">Jugador 2</th>
      <th scope="col">Jugador 3</th>
      <th scope="col">Jugador 4</th>
      <th scope="col">E.</th>
      <th scope="col">FF,</th>
      <th scope="col">Cant.</th>
    </tr>
  </thead>
  <tbody>
  {hours && hours.length>0 && hours.map(
            (h,i)=>
            <tr>
               <td>{ h.hour_date}</td>
               <td>{h.canchaId}</td>
               <td>{nf(h.hour_hour)} : {nf(h.hour_time)}</td>
               <td> {h.reservation_name} </td>
               <td> {h.reservation_phone} </td>
               <td>  
                <select value={h.player1_pay} onChange={(e)=>update(h,h.reservationId,1,e.target.value)}>
                  <option value="">No Indicado</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Debito">Debito</option>
                  <option value="Credito">Credito</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Debe">Debe</option>
                </select>
               </td>
               <td>  
                <select value={h.player2_pay} onChange={(e)=>update(h,h.reservationId,2,e.target.value)}>
                  <option value="">No Indicado</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Debito">Debito</option>
                  <option value="Credito">Credito</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Debe">Debe</option>
                </select>
               </td><td>  
                <select value={h.player3_pay} onChange={(e)=>update(h,h.reservationId,3,e.target.value)}>
                  <option value="">No Indicado</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Debito">Debito</option>
                  <option value="Credito">Credito</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Debe">Debe</option>
                </select>
               </td><td>  
                <select value={h.player4_pay} onChange={(e)=>update(h,h.reservationId,4,e.target.value)}>
                  <option value="">No Indicado</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Debito">Debito</option>
                  <option value="Credito">Credito</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Debe">Debe</option>
                </select>
               </td>
               <td><svg onClick={()=>deleteHour(h)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
              </svg></td>
              <td>
              <svg onClick={()=>fixed(h)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggles" viewBox="0 0 16 16">
                <path d="M4.5 9a3.5 3.5 0 1 0 0 7h7a3.5 3.5 0 1 0 0-7h-7zm7 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-7-14a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm2.45 0A3.49 3.49 0 0 1 8 3.5 3.49 3.49 0 0 1 6.95 6h4.55a2.5 2.5 0 0 0 0-5H6.95zM4.5 0h7a3.5 3.5 0 1 1 0 7h-7a3.5 3.5 0 1 1 0-7z"/>
              </svg>
              </td>
              <td>
                  <input type='numeric' style={{width:'40px'}} onChange={(e)=>h.quantity=e.target.value}></input>
              </td>
            </tr>
            
          )}
    
  </tbody>
</table>
         
      </main>

    </div>);
  };


export default CreateDate;

