import React from 'react';
import { useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
  
const ClassInfo = () => {
  const navigate = useNavigate();
  const images = [
    {
      original: "/img/club1.jpeg",
      thumbnail: "/img/club1.jpeg",
    },
    {
      original: "/img/club2.jpeg",
      thumbnail: "/img/club2.jpeg",
    },
    {
      original: "/img/club3.jpeg",
      thumbnail: "/img/club3.jpeg",
    },
    {
      original: "/img/club4.jpeg",
      thumbnail: "/img/club4.jpeg",
    }
  ];

  
  return (
    <div className="portal-container">
     <header>
      <div className='row'>
           <div className='col-1'  onClick={()=>navigate(-1)} >
          <svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" headerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="headerFont topBar">
  OPEN PADEL </span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
        <div className='row'>
              <span style={{ marginTop:'20px'}} onClick={()=>document.location.href='/'} className="btn btn-sm btn-outline-secondary btnFont text-secondary">RESERVAR CANCHA O TOMAR CLASE</span>
            </div>
           <div className='row'>
           
              <div className="card card-body">
              <span className="card-text" style={{width:'100%', marginTop:'10px'}}>
                  <ImageGallery items={images} />
              </span>
              <span className="card-text" style={{width:'100%', marginTop:'50px'}}>Open Padel es un Club de Padel qué cuenta con dos canchas full panorámicas de vidrio. Servicio de cantina, Vestuarios, baños y Parrillero.
Ofrecemos clases para todo tipo de jugador, desde iniciante a niveles avanzados.
Se realizan campeonatos de todas las categorías 
 a nivel departamental y nacional.</span>
                <span className="card-text" style={{width:'100%', marginTop:'50px'}}>Ubicado en Intendente Juan Hola Paiva, 50000 Salto, Departamento de Salto</span>
                <span className="card-text" style={{width:'100%'}}>
                  <a href="https://maps.app.goo.gl/SQv55U9EBinaZGXw5" rel="noreferrer"  target="_blank"><img className='logo' src='/img/mapa.png'></img></a>
                </span>
              </div>
            </div>
            




           
         </div>
         
      </main>

     
    </div>
    );
  };


export default ClassInfo;
