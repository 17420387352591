import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import {PATH_TO_API,API_GET_HEADERS} from './util/constants';
 
const Confirm = () => {
  const navigate = useNavigate();
  const { type, hourId, saleDate, cancha, hour } = useParams();
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [resOk, setResOk] = useState(1);
  const [canchaSel, setCanchaSel] = useState(null);
  function createData(){
    fetch(PATH_TO_API('reservation/'+hourId+'/'+phone+'/'+name+'/'+saleDate+'/'+cancha),API_GET_HEADERS).then(res=>res.json()).then(res=>{
      setResOk(res.res?2:3);
    })
   
  }
  useEffect(()=>{
    async function fetchData(){
      fetch(PATH_TO_API('cancha/'+cancha),API_GET_HEADERS).then(res=>res.json()).then(res=>{
        setCanchaSel(res);
      })
       
    }
    fetchData();
  },[]);

  const next=()=>{
    if(name==null || name.trim()==''){
      document.getElementById('lbl1').style="display:block";
    }else if(phone==null || phone.trim()==''){
      document.getElementById('lbl2').style="display:block";
    }else{
      createData();
    }
  }
  

  return (
    <div className="portal-container">
     <header>
      {resOk==1? <div className='row'>
           <div className='col-1'  onClick={()=>navigate(-1)} >
          <svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="footerFont topBar">
  CONFIRME</span>
  </div>
  </div>:resOk==2?<div className='row'>
        <div className='col-1'  onClick={()=>navigate(-1)} >
        <svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
              </svg>
          </div> 
          <div className='col-11'>
        <span className="footerFont topBar">
    CONFIRMADO!!</span>
    </div>
    </div>:<div className='row'>
<div className='col-1'  onClick={()=>navigate(-1)} >
<svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
      </svg>
  </div> 
  <div className='col-11'>
<span className="footerFont topBar">
BUSQUE OTRA FECHA</span>
</div>
</div>
    }
      </header>
      {resOk==1?
      <main>
        <div className='container'>
           <div className='row'>
            <div class="card" >
            {canchaSel &&<img className='cancha' src={canchaSel.cancha_img}></img>}
              <div className="card-body">
              {canchaSel &&<h5 className="card-title">{canchaSel.cancha_title}</h5>}
                <p className="card-text">
                {saleDate && 
            <div className='row footerFont' >
              Fecha seleccionada {saleDate}
            </div>}
            {hour && 
            <div className='row footerFont' >
              Hora seleccionada {hour.replace("_",":")}
            </div>}
            
                </p>
              </div>
            </div>
            <div className="card-body"  style={{marginTop:'30px'}}>
            <div className="input-group mb-3">
              <div class="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Nombre</span>
              </div>
              <input type="text"  style={{width:'60%'}}  onChange={(e)=>{document.getElementById('lbl1').style="display:none";  setName(e.target.value)}}  className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
              <br></br>
              <span id="lbl1" style={{display:'none'}}>*Indique Nombre</span>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default">Celular</span>
              </div>
              <input type="number" style={{width:'60%'}} onChange={(e)=>{ document.getElementById('lbl2').style="display:none"; setPhone(e.target.value)}} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
              <br></br>
              <span id="lbl2" style={{display:'none'}}>*Indique Celular</span>
            </div>
                </div>
           </div>
          </div>
      </main>:resOk==2?
      <main>
       <div className='container'>
          <span className="footerFond confirm">Reserva confirmada {saleDate} {hour.replace("_",":")} {name} {phone} lo esperamos!!</span>
       
      </div>
        </main>:
        <main>
        <div className='container'>
           <span className="footerFond confirm">YA EXISTE RESERVA PARA {saleDate} {hour.replace("_",":")} VUELVA E INDIQUE OTRO HORARIO</span>
       </div>
         </main>
      }
      <footer className='footerBlanco'>
           <div className='row'>
            <div className='col-6' >
              <span onClick={()=>navigate(-1)} className="btn btn-sm btn-outline-secondary btnFont  text-secondary marginBtnToolBar" style={{paddingRight:'40px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-left-short arrow" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                HORARIO</span>
              </div>
              <div className='col-6'>
                {resOk==1? 
                <span onClick={()=>next()} className="footerVerde btn btn-sm btn-outline-secondary btnFont  text-secondary marginBtnToolBar">
                  CONFIRMAR
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-right-short arrow" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  </span>:
                  <span onClick={()=>navigate('/')} className="footerVerde btn btn-sm btn-outline-secondary btnFont   text-secondary marginBtnToolBar">
                  INICIO
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-right-short arrow" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  </span>}
                </div>
          </div>
      </footer>

     
    </div>);
  };


export default Confirm;
