import React from 'react';


const Portal = () => {
 
  return (
    <div className="portal-container fondo2">
      <main>
          
        <div className='container' >
           
             <img className='logo' src="/img/logo.png"></img>
            <div className='row'>
              <span  style={{marginTop:'20px'}} onClick={()=>document.location.href='/SelectDate/comun'} className="btn btn-sm btn-outline-secondary btnFont text-secondary">RESERVAR CANCHA</span>
            </div>
            <div className='row'>
            <span onClick={()=>document.location.href='/ClassInfo'} style={{marginTop:'20px'}} className="btn btn-sm btn-outline-secondary btnFont  text-secondary">TOMAR CLASES</span>
                </div>
                <div className='row'>
              <span onClick={()=>document.location.href='/info'} style={{marginTop:'20px'}} className="btn btn-sm">Sobre Open Padel</span>
            </div>
            </div>
      </main>

      <footer>
        <a href="https://wa.me/598099864997" className='footerFont'><img className='wp' src="img/wp.png"></img>WhatsApp -099864997-</a>
      </footer>
    </div>
  );
};

export default Portal;




