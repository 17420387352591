import React from 'react';
import { useNavigate } from "react-router-dom";

  
const ClassInfo = () => {
  const navigate = useNavigate();

  return (
    <div className="portal-container">
     <header>
      <div className='row'>
           <div className='col-1'  onClick={()=>navigate(-1)} >
          <svg xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" headerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="headerFont topBar">
  TOMAR CLASES</span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
           <div className='row'>
              <div className="card card-body">
                <span className="card-text" style={{width:'100%'}}>Clases a cargo del profe Tomás Niño Broggi</span>
                <span className="card-text" style={{width:'100%'}}>Para todos los niveles, desde iniciantes sin conocimiento a niveles avanzados.</span>
                <span className="card-text" style={{width:'100%'}}>Horarios a convenir a partir de las 8:00am hasta las 16:00hs</span>
                <span className="card-text" style={{width:'100%'}}>Los precios por clase, por persona son los siguientes:</span>
                <ul>
                  <li>Individual $550</li>
                  <li>Pareja de dos $450</li>
                  <li>Tres y cuatro personas $350</li>
                </ul>
                <span className="card-text"  style={{width:'100%'}}>Contactar para mas informacioán o coordinar clases. </span>
                <span className="card-text"  style={{width:'100%'}}>
                <a href="https://wa.me/5493454321864" ><img className='wp' src="img/wp.png"></img>+54 9 3454 32-1864-</a>
                </span>
              </div>
            </div>
            




           
         </div>
      </main>

     
    </div>
    );
  };


export default ClassInfo;
