import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {PATH_TO_API,API_GET_HEADERS,nf} from './util/constants';
import { useNavigate } from "react-router-dom";
 
const Home = () => {

    const navigate = useNavigate();
    const [hour, setHour] = useState(null);
    const [hourId, setHourId] = useState(null);
    const [canchaSel, setCanchaSel] = useState(null);
    const [hourSel, setHourSel] = useState(null);
    const { type, saleDate , cancha} = useParams();
    
    useEffect(()=>{
      async function fetchData(){
        fetch(PATH_TO_API('cancha/'+cancha),API_GET_HEADERS).then(res=>res.json()).then(res=>{
          setCanchaSel(res);
        })
        fetch(PATH_TO_API('hours/'+cancha+'/'+saleDate),API_GET_HEADERS).then(res=>res.json()).then(res=>{
          setHourSel(res.filter((h)=>h.hour_type==type));
        })    
      }
      fetchData();
    },[]);

    const next=()=>{
      if(hour==null || hour.trim().length==0){

      }else
       navigate("/Confirm/"+type+'/'+saleDate+'/'+cancha+'/'+hour+'/'+hourId);
    }
  
   
  return (
    <div className="portal-container">
     <header>
      <div className='row'>
           <div className='col-1'>

           <svg onClick={()=>navigate(-1)} xmlns="http://www.w3.org/2000/svg" style={{position:'relative', top:'-13px'}} width="64" height="64" fill="currentColor" className=" footerFont bi bi-arrow-left-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
            </div> 
            <div className='col-11'>
          <span className="footerFont">
  SELECCIONE HORARIO</span>
  </div>
          </div>
      </header>
      <main>
        <div className='container'>
           <div className='row card-body'>
           {saleDate && 
            <div className='row footerFont' >
              Fecha seleccionada {saleDate}
            </div>}
            {hour && 
            <div className='row footerFont' >
              Hora seleccionada {hour.replace("_",":")}
            </div>}
            {!hour && hourSel && hourSel.length>0 && 
            <div id='lbl1' className='row footerFont' >
              Seleccione hora
            </div>}
           {canchaSel && <img className='cancha' src={canchaSel.cancha_img}></img>}
           {canchaSel && <span className='footerFont card-text'>{canchaSel.cancha_title}</span>}
            </div>
            {hourSel && hourSel.length>0 && hourSel.map(
              (h,i)=><div className='row'>
              <span onClick={()=>{setHourId(h.id);setHour(nf(h.hour_hour)+'_'+nf(h.hour_time));}} className="btn btn-sm btn-outline-secondary btnFont btnMarginTop2 text-secondary">{nf(h.hour_hour)+':'+nf(h.hour_time)+'  '+nf(h.hour_end_hour)+':'+nf(h.hour_end_time)+'h'} ${h.hour_pricin}</span>
            </div>
            )}
            {hourSel && hourSel.length==0 &&
              <div id='lbl2' className='row footerFont' >
              No hay horarios disponibles en la fecha seleccionada para la cancha indicada, volver para seleccionar otra canca
            </div>}
            {saleDate && 
            <div className='row footerFont' >
              Fecha seleccionada {saleDate}
            </div>}
            {hour && 
            <div className='row footerFont' >
              Hora seleccionada {hour.replace("_",":")}
            </div>}
            {!hour && hourSel && hourSel.length>0 &&
            <div id='lbl1' className='row footerFont' >
              Seleccione hora
            </div>}
         </div>
      </main>

      <footer className='footerBlanco'>
           <div className='row'>
            <div className='col-6' >
              <span onClick={()=>navigate(-1)}className="btn btn-sm btn-outline-secondary btnFont  text-secondary marginBtnToolBar" style={{paddingRight:'40px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-left-short arrow" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                CANCHA</span>
              </div>
              <div className='col-6'>
                <span onClick={()=>next()} className="footerVerde btn btn-sm btn-outline-secondary btnFont text-secondary marginBtnToolBar">
                  CONTINUAR
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="30" fill="currentColor" className="bi bi-arrow-right-short arrow" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                  </span>
                </div>
          </div>
      </footer>
    </div>);
  };


export default Home;
